/* You can add global styles to this file, and also import other style files */

.ck-editor__editable_inline {
  min-height: 320px;
  min-width: 200px;
}

._bg_transprent {
  background: transparent !important;
}

#readMore {
  cursor: pointer;
}

._app_chart_box.col-sm-6 {
  border: 1px solid #ddd;
  background: #fff;
  border-radius: 10px;
  margin-bottom: 20px;
  margin-right: 10px;
  flex: 0 0 49%;
  max-width: 49%;
  padding: 0;
}

._chart_title {
  background: #00ACD8;
  padding: 11px 15px;
  color: #fff;
  margin-bottom: 5px;
  border-radius: 5px 5px 0 0;
  font-weight: 600;
}

._chart_body {
  padding: 15px;
}

._justify_content_space_even {
  justify-content: space-evenly;
}

.mr0 {
  margin-right: 0 !important;
}

button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  font-size: 96% !important;
  line-height: 1.5 !important;
  border: none;
}

.multiselect-dropdown .dropdown-btn .selected-item:hover {
  box-shadow: 3px 3px #959595 !important;
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.show-hide-icon {
  width: 20px;
}

._mobile_no_field {
  padding-top: 30px;
}

.pos-relative.app-root {
  position: relative;
  height: 100%;
  display: inline-table;
  width: 100%;
}

.mat-form-field-infix {
  position: relative !important;
  top: -10px;
}

._country_code_section .mat-form-field-infix {
  text-align: center;  
}

.mat-form-field-appearance-legacy .mat-form-field-underline {
  height: 0 !important;
}