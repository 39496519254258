.main-wrapper {
  position: relative;
  width: 100%
}

@-ms-viewport {
  width: device-width;
}

* {
  -webkit-tap-highlight-color: transparent;
  -webkit-transition: 0.3s all;
  -o-transition: 0.3s all;
  transition: 0.3s all;
}

*,
html {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

html {
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
}

::after,
::before {
  -webkit-box-sizing: inherit;
  box-sizing: inherit;
}

.container,
.container-fluid {
  width: 100%;
  padding: 25px;
  padding-top: 0;
  margin-right: auto;
  margin-left: auto;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}

.no-gutters>.col,
.no-gutters>[class*="col-"] {
  padding-right: 0;
  padding-left: 0;
}

.col,
.col-1,
.col-10,
.col-11,
.col-12,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9 {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 10px;
  padding-left: 10px;
}

.col-auto {
  position: relative;
  min-height: 1px;
  padding-right: 10px;
  padding-left: 10px;
}

.col-lg,
.col-lg-1,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-auto,
.col-md,
.col-md-1,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-auto,
.col-sm,
.col-sm-1,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-auto,
.col-xl,
.col-xl-1,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-auto,
.col-xxl,
.col-xxl-1,
.col-xxl-10,
.col-xxl-11,
.col-xxl-12,
.col-xxl-2,
.col-xxl-3,
.col-xxl-4,
.col-xxl-5,
.col-xxl-6,
.col-xxl-7,
.col-xxl-8,
.col-xxl-9,
.col-xxl-auto {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 10px;
  padding-left: 10px;
}

.col {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 100%;
}

.col-auto {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: auto;
  max-width: none;
}

.col-1,
.col-2,
.col-3 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 8.33333%;
  flex: 0 0 8.33333%;
  max-width: 8.33333%;
}

.col-2,
.col-3 {
  -ms-flex: 0 0 16.66667%;
  flex: 0 0 16.66667%;
  max-width: 16.66667%;
}

.col-3 {
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4,
.col-5,
.col-6 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 33.33333%;
  flex: 0 0 33.33333%;
  max-width: 33.33333%;
}

.col-5,
.col-6 {
  -ms-flex: 0 0 41.66667%;
  flex: 0 0 41.66667%;
  max-width: 41.66667%;
}

.col-6 {
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7,
.col-8,
.col-9 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 58.33333%;
  flex: 0 0 58.33333%;
  max-width: 58.33333%;
}

.col-8,
.col-9 {
  -ms-flex: 0 0 66.66667%;
  flex: 0 0 66.66667%;
  max-width: 66.66667%;
}

.col-9 {
  -ms-flex: 0 0 75%;
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  -ms-flex: 0 0 83.33333%;
  flex: 0 0 83.33333%;
  max-width: 83.33333%;
}

.col-10,
.col-11,
.col-12 {
  -webkit-box-flex: 0;
}

.col-11 {
  -ms-flex: 0 0 91.66667%;
  flex: 0 0 91.66667%;
  max-width: 91.66667%;
}

.col-12 {
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
}

body,
html {
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
}

a {
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

a,
a:hover {
  color: inherit;
  text-decoration: none;
}

ol,
ul {
  list-style-type: none;
  padding-left: 0;
}

body {
  background-color: transparent;
  margin: 0;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
}

.main-title {
  font-weight: 700;
  font-size: 24px;
  line-height: 1.42;
  color: #171717;
  margin-top: 0;
  margin-bottom: 0;
  display: flex;
  align-items: center;
}

button {
  cursor: pointer;
  -webkit-transition: 0.3s all;
  -o-transition: 0.3s all;
  transition: 0.3s all;
  border: none;
}

.button {
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  -khtml-border-radius: 5px;
  border-radius: 5px;
  -moz-box-sizing: border-box !important;
  cursor: pointer;
  display: inline-block;
  font-size: 15px;
  font-weight: 500;
  line-height: 16px;
  outline: none !important;
  padding: 12px 10px;
  position: relative;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;
  line-height: 1.4;
}

.btn {
  font-weight: 500;
}

.btn-primary {
  background-color: #00acd8;
  border-color: #00acd8;
}

.btn-primary:hover {
  background: #008eb3 !important;
  color: #fff !important;
  border-color: #008eb3 !important;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.42) !important;
}

.btn:focus-visible,
.btn:active {
  background-color: #008eb3;
  border-color: #008eb3;
  outline: 0;
  box-shadow: none;
}

.ngx-pagination .current {
  background-color: #008eb3 !important;
}

.ngx-pagination li {
  margin: 0 6px;
  background: white !important;
  border-radius: 3px !important;
}

article {
  display: block;
}

form {
  display: block;
  margin-top: 0em;
}

label {
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  cursor: pointer;
}

input {
  border-radius: 6px;
  background-color: #eff0f6;
}

button,
input {
  overflow: visible;
}

button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
}

.form {
  -webkit-box-shadow: 0 5px 10px rgba(160, 163, 189, 0.1);
  box-shadow: 0 5px 10px rgba(160, 163, 189, 0.1);
  border-radius: 10px;
  padding: 25px;
  background-color: #fff;
  text-align: left;
}

.form-input {
  border-radius: 8px;
  border-width: 0;
  margin-bottom: 15px;
  padding: 5px 16px;
  height: 50px;
  background: #eff0f6 !important;
  /* border: 1px solid #dddddd78; */
}

._formPreview_section .form-input {
  background: #fff !important;
  border: 1px solid #ddd;
}

textarea.form-input {
  height: 100px;
}

.form-input::placeholder {
  font-weight: 500;
  font-size: 14px;
  line-height: 2.43;
  color: #000;
}

input:focus,
textarea:focus,
textarea:focus-visible {
  outline: none;
  border: none;
  -webkit-box-shadow: 0 0 0 2px rgba(134, 182, 254, 0.5);
  box-shadow: 0 0 0 2px rgba(134, 182, 254, 0.5);
}

.primary-btn {
  background-color: #21507e;
  color: #fff;
  border: 1px solid #21507e;
}

.primary-btn:hover {
  background-color: #e7f0f8;
  color: #21507e;
  border: 1px solid #aabcce;
}

.primary-submit-btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  border-radius: 4px;
  padding: 10px 20px;
}

._continue_btn {
  padding: 9px 20px !important;
  border-radius: 30px;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#1874FD), to(#1874FD));
  background-image: linear-gradient(180deg, #1874FD, #1874FD);
  color: #fff !important;
  font-size: 16px;
  text-decoration: none;
  border: none !important;
}

._continue_btn:hover {
  background-image: -webkit-gradient(linear, left top, left bottom, from(#025BDE), to(#025BDE));
  background-image: linear-gradient(180deg, #025BDE, #025BDE);
  text-decoration: none;
}

.form-btn {
  width: 100%;
}

.form-label-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
}

.form-label {
  font-weight: 500;
  font-size: 14px;
  line-height: 2.43;
  color: #171717;
  cursor: pointer;
  margin-bottom: 5px;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
}

.white-block {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  background-color: #fff;
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 20px;
}

._justify_content_center {
  justify-content: center !important;
}

.mr_none {
  margin: 0 !important;
}

.mt0 {
  margin-top: 0 !important;
}

.mt10 {
  margin-top: 10px !important;
}

.mt15 {
  margin-top: 15px !important;
}

.mt20 {
  margin-top: 20px !important;
}

.mt30 {
  margin-top: 30px !important;
}

.mt40 {
  margin-top: 40px !important;
}

.pd30 {
  padding: 30px !important;
}

.pt10 {
  padding-top: 10px !important;
}

.pb10 {
  padding-bottom: 10px !important;
}

.pl0 {
  padding-left: 0 !important;
}

.pl4 {
  padding-left: 4px !important;
}

.pl10 {
  padding-left: 10px !important;
}

.pl5 {
  padding-left: 5px !important;
}

.pl15 {
  padding-left: 15px !important;
}

.pr0 {
  padding-right: 0 !important;
}

.pr10 {
  padding-right: 10px !important;
}

.pr15 {
  padding-right: 15px !important;
}

.mb0 {
  margin-bottom: 0px !important;
}

.mb5 {
  margin-bottom: 5px !important;
}

.mb10 {
  margin-bottom: 10px !important;
}

.mb15 {
  margin-bottom: 15px !important;
}

.mb20 {
  margin-bottom: 20px !important;
}

.mlnone {
  margin-left: 0 !important;
}

.ml10 {
  margin-left: 10px !important;
}

.ml15 {
  margin-left: 15px !important;
}

.mrnone {
  margin-right: 0 !important;
}

.mr5 {
  margin-right: 5px !important;
}

.mr10 {
  margin-right: 10px !important;
}

.mr15 {
  margin-right: 15px !important;
}

.fs22 {
  font-size: 22px;
  font-weight: 500;
}

.w100 {
  width: 100%;
}

.w50 {
  width: 50%;
}

.modal-header {
  background-color: #00acd8;
  color: #fff;
  align-items: baseline;
}

.modal-header .close {
  background: #fff;
  border-radius: 50%;
  height: 26px;
  width: 26px;
  font-size: 17px !important;
  line-height: 18px !important;
  font-weight: 600;
}

.modal-body .form-control {
  padding: 0.5rem 0.75rem;
  margin-bottom: 15px;
}

.modal-body label {
  margin-bottom: 5px;
}

/*custom css*/
.white-section {
  background-color: #fff;
  border-radius: 10px;
  padding: 20px 0;
  margin-bottom: 20px;
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

table tr th {
  font-weight: 600;
  color: #fff !important;
  font-size: 18px;
  background-color: #00acd8;
}

input[type="text"],
input[type="email"],
input[type="number"],
textarea,
select,
._token_input {
  width: 100%;
  border-radius: 6px;
  background-color: #eff0f6;
  padding: 10px 16px;
  /* height: 45px; */
  /* border: 1px solid #dddddd78; */
  font-weight: 500;
}


._token_input {
  padding: 10px 3px !important;
  font-weight: 600 !important;
  font-size: 12px !important;
}

._white_bx {
  background: #fff;
  padding: 20px 30px;
  border: 1px solid #ddd;
}

._text-left {
  text-align: left;
}

._text_center {
  text-align: center;
}

._text_right {
  text-align: right;
}


@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }

  .col-sm {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }

  .col-sm-auto {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }

  .col-sm-1,
  .col-sm-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 8.33333%;
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }

  .col-sm-2 {
    -ms-flex: 0 0 16.66667%;
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }

  .col-sm-3,
  .col-sm-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-sm-4 {
    -ms-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }

  .col-sm-5,
  .col-sm-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 41.66667%;
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }

  .col-sm-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-sm-7,
  .col-sm-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 58.33333%;
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }

  .col-sm-8 {
    -ms-flex: 0 0 66.66667%;
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }

  .col-sm-10,
  .col-sm-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-sm-10 {
    -ms-flex: 0 0 83.33333%;
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }

  .col-sm-11,
  .col-sm-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 91.66667%;
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }

  .col-sm-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }

  .col-md {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }

  .col-md-auto {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }

  .col-md-1,
  .col-md-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 8.33333%;
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }

  .col-md-2 {
    -ms-flex: 0 0 16.66667%;
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }

  .col-md-3,
  .col-md-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-md-4 {
    -ms-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }

  .col-md-5,
  .col-md-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 41.66667%;
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }

  .col-md-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-md-7,
  .col-md-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 58.33333%;
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }

  .col-md-8 {
    -ms-flex: 0 0 66.66667%;
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }

  .col-md-10,
  .col-md-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-md-10 {
    -ms-flex: 0 0 83.33333%;
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }

  .col-md-11,
  .col-md-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 91.66667%;
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }

  .col-md-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }

  .col-lg {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }

  .col-lg-auto {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }

  .col-lg-1,
  .col-lg-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 8.33333%;
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }

  .col-lg-2 {
    -ms-flex: 0 0 16.66667%;
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }

  .col-lg-3,
  .col-lg-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-lg-4 {
    -ms-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }

  .col-lg-5,
  .col-lg-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 41.66667%;
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }

  .col-lg-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-lg-7,
  .col-lg-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 58.33333%;
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }

  .col-lg-8 {
    -ms-flex: 0 0 66.66667%;
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }

  .col-lg-10,
  .col-lg-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-lg-10 {
    -ms-flex: 0 0 83.33333%;
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }

  .col-lg-11,
  .col-lg-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 91.66667%;
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }

  .col-lg-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }

  .col-xl {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }

  .col-xl-auto {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }

  .col-xl-1,
  .col-xl-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 8.33333%;
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }

  .col-xl-2 {
    -ms-flex: 0 0 16.66667%;
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }

  .col-xl-3,
  .col-xl-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-xl-4 {
    -ms-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }

  .col-xl-5,
  .col-xl-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 41.66667%;
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }

  .col-xl-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-xl-7,
  .col-xl-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 58.33333%;
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }

  .col-xl-8 {
    -ms-flex: 0 0 66.66667%;
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }

  .col-xl-10,
  .col-xl-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-xl-10 {
    -ms-flex: 0 0 83.33333%;
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }

  .col-xl-11,
  .col-xl-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 91.66667%;
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }

  .col-xl-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media (min-width: 1520px) {
  .container {
    max-width: 1660px;
  }

  .col-xxl {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }

  .col-xxl-auto {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }

  .col-xxl-1,
  .col-xxl-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 8.33333%;
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }

  .col-xxl-2 {
    -ms-flex: 0 0 16.66667%;
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }

  .col-xxl-3,
  .col-xxl-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-xxl-4 {
    -ms-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }

  .col-xxl-5,
  .col-xxl-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 41.66667%;
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }

  .col-xxl-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-xxl-7,
  .col-xxl-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 58.33333%;
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }

  .col-xxl-8 {
    -ms-flex: 0 0 66.66667%;
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }

  .col-xxl-10,
  .col-xxl-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-xxl-10 {
    -ms-flex: 0 0 83.33333%;
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }

  .col-xxl-11,
  .col-xxl-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 91.66667%;
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }

  .col-xxl-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media (max-width: 1199.98px) {
  .sidebar {
    position: fixed;
    z-index: 2;
    overflow-y: auto;
    left: -1000px;
  }

  .main-nav {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
  }
}

@media (max-width: 767.98px) {
  .main-nav {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
  }

  .main-nav-start,
  .main-nav-start input {
    width: 100%;
  }

  .main-nav-end {
    margin-bottom: 20px;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    width: 100%;
  }
}

@media (max-width: 480.98px) {
  .main-title {
    font-size: 20px;
  }
}

.star {
  font-size: 1.5rem;
  color: #b0c4de;
}

.filled.color {
  color: orange;
}

.dsp-flex {
  display: flex;
  align-items: center;
}

.clr-red {
  color: red;
}

form label {
  padding: 0 !important;
}

.dsp-flx {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.brd-button {
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 5px 8px;
  font-size: 13px;
  display: inline-block;
}

.brd-button:hover {
  background: #E7F0F8;
}

.search-wrapper .fas.fa-times-circle {
  left: inherit !important;
  right: 10px;
}

.log_out_btn {
  padding: 2px 7px !important;
  font-size: 13px;
  border-radius: 3px;
}

.table-wrapper {
  overflow-x: inherit !important;
  overflow-y: inherit !important;
}

.flx-grow1 {
  flex-grow: 1;
}

._flx_only {
  display: flex;
}

._btn_brd {
  border-top: 1px solid #ddd;
  margin-top: 20px;
  padding-top: 20px;
}

.modal-footer .btn-outline-dark {
  background-color: #fff;
  border: 1px solid #73c2d6;
  border-radius: 5px;
  color: #0190b5;
}

.modal-footer .btn-outline-dark:hover {
  background-color: #00acd8;
  color: #fff;
  border-color: #00acd8;
}

.modal-footer {
  padding-bottom: 0;
}

._align_flxstart {
  align-items: flex-start !important;
}

._subject_section {
  width: 49.5%;
  display: flex;
  align-items: center;
}

._ckeditor_body {
  width: 75.5%;
}

._dsp_flx_inputrow {
  display: flex;
}

._dsp_flx_row {
  display: flex;
  align-items: center;
}

._dsp_flx_row p {
  margin-bottom: 0 !important;
}

.mr30 {
  margin-right: 30px !important;
}

.modal-dialog {
  max-width: 40%;
}

.page-flex {
  background-color: #e7f0f8;
  /* max-height: 100%; */
  min-height: 100vh;
  padding-bottom: 30px !important;
}

._invite_link {
  display: flex;
  margin-top: 15px;
  border-radius: 5px;
}

._invite_link span {
  padding: 10px 15px;
  background: #00ACD8;
  color: #fff;
  border-radius: 5px 0 0 5px;
  font-size: 15px;
}

._invite_link a {
  border: 1px solid #ddd;
  padding: 10px 15px;
  border-radius: 0 5px 5px 0;
  background: #EFF0F6;
}

.mat-expansion-indicator::after {
  color: #fff;
  border-width: 0 3px 3px 0 !important;
}

._review_right_content ._review_list_item {
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  padding: 15px 20px 15px;
  margin: 0 15px 0;
  background: #f9f9f9;
  min-height: 148px;
}

._review_from_wrap .white-block {
  box-shadow: 0 5px 10px #a0a3bd1a;
  border: 1px solid rgba(0, 0, 0, .1);
}

._review_from_wrap {
  width: 1290px;
  margin: auto;
}

._review_right_content {
  padding-top: 20px;
  padding-bottom: 20px;
}

._review_right_content ._list_top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 3px;
}

._review_right_content ._list_top_left {
  display: flex;
  align-items: center;
}

._review_right_content ._avtar {
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 600;
  color: #21507e;
  background: #e7f0f8;
  border: 1px solid #bdd1e6;
  border-radius: 10px;
  margin-right: 10px;
}

._review_right_content ._avtar img {
  width: 100%;
}

._list_des {
  font-size: 17px;
  margin-top: 10px;
  line-height: 1.3;
}


._review_right_content ._avtar_name {
  font-size: 17px;
  font-weight: 600;
}

._add_contact_p {
  width: 70%;
  margin: auto;
  border: 1px solid #ddd;
  padding: 0;
  border-radius: 10px;
}

._add_contact_p .container,
._add_contact_p .container-fluid {
  padding: 0;
}

._add_contact_p .main-title {
  border-bottom: 1px solid #ddd;
  padding: 15px 20px;
}

.pl20 {
  padding-left: 20px !important;
}

.carousel-indicators,
.carousel-control-next,
.carousel-control-prev {
  display: none;
}